import { env } from '~/utils/env';

type ApiDetails = {
  url: string;
  scope: string;
};

export const API = {
  PortalSms: {
    url: env.portalApiUrl,
    scope: `api://${env.portalApiAppId}/user_impersonation`,
  },
} satisfies Record<string, ApiDetails>;
