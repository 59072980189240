export const QueryKey = {
  GraphGroups: 'graphGroups',
  GraphGroupMembers: 'graphGroupMembers',
  GraphUsers: 'graphUsers',
  ExternalRecipients: 'externalRecipients',
  MessageDeliveries: 'messageDeliveries',
  MessageSenders: 'messageSenders',
  Company: 'company',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
