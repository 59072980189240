import { faGear, faList, faSms, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, Nav, useTheme } from '@intility/bifrost-react';
import { IntilityAppsReactQuery } from '@intility/platform-toolkit-msal/react-query';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import { intilitySms } from '~/assets';
import { useMsalRouterIntegration } from '~/auth/useMsalRouterIntegration';
import { routes } from '~/routes/config';
import { useCurrentStep, useSmsStoreActions } from '~/stores/smsStore';
import { cn } from '~/utils/clsx';
import i18n from '~/utils/i18n.config';

const Navigation = () => {
  useMsalRouterIntegration();
  const { t } = useTranslation();
  const { setCurrentStep } = useSmsStoreActions();
  const currentStep = useCurrentStep();
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const locale = i18n.language;

  return (
    <Nav
      appName={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img src={intilitySms} alt='logo' style={{ height: '24px', width: '24px' }} />
          <p className='bf-open-sans'>Intility SMS</p>
        </div>
      }
      hideTheme
      logoOnClick={e => {
        e.preventDefault();
        navigate('/');
        setCurrentStep('welcome');
      }}
      top={
        <>
          <IntilityAppsReactQuery />
          <Nav.Group icon={faUser}>
            <div className='my-bfs-16'>
              <div className='mx-bfs-24 text-xs text-bfc-base-c-2'>{t('Color theme')}</div>
              <Checkbox
                type='radio'
                name='theme'
                label={t('Light')}
                checked={theme === 'light'}
                onChange={() => setTheme('light')}
              />
              <Checkbox
                type='radio'
                name='theme'
                label={t('Dark')}
                checked={theme === 'dark'}
                onChange={() => setTheme('dark')}
              />
              <Checkbox
                type='radio'
                name='theme'
                label={t('System')}
                checked={theme === 'system'}
                onChange={() => setTheme('system')}
              />
            </div>

            <div className='my-bfs-16'>
              <div className='mx-bfs-24 text-xs text-bfc-base-c-2'>{t('Language')}</div>
              <Checkbox
                type='radio'
                name='language'
                label='English'
                checked={locale === 'en-US'}
                onChange={() => void i18n.changeLanguage('en-US')}
              />
              <Checkbox
                type='radio'
                name='language'
                label='Norsk'
                checked={locale === 'nb-NO'}
                onChange={() => void i18n.changeLanguage('nb-NO')}
              />
            </div>
          </Nav.Group>
        </>
      }
      side={
        <>
          <NavLink
            className={({ isActive }) => cn({ active: isActive && currentStep !== 'welcome' })}
            to={routes.create.path}
            onClick={() => setCurrentStep('recipients')}
            end
          >
            <Nav.Item icon={faSms}>{t('Send SMS')}</Nav.Item>
          </NavLink>
          <hr />
          <NavLink to={routes.history.path}>
            <Nav.Item icon={faList}>{t('History')}</Nav.Item>
          </NavLink>
          <hr />
          <NavLink to={routes.settings.path}>
            <Nav.Item icon={faGear}>{t('Settings')}</Nav.Item>
          </NavLink>
          <hr />
        </>
      }
    >
      <Outlet />
    </Nav>
  );
};

export default Navigation;
